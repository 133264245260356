/**
 * @generated SignedSource<<cfa661ec263f590b97a1eebbcfa791e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AttributionWindowState = "ATTRIBUTION_WINDOW_STATE_NULL" | "ATTRIBUTION_WINDOW_STATE_SET" | "ATTRIBUTION_WINDOW_STATE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AttributionSettings_company$data = {
  readonly attributionSettings: {
    readonly emailClickWindow: {
      readonly value: number;
    };
    readonly emailViewWindow: {
      readonly attributionWindowState: AttributionWindowState;
      readonly value: number;
    };
    readonly filterMachineOpened: boolean;
    readonly textClickWindow: {
      readonly value: number;
    };
    readonly textViewWindow: {
      readonly attributionWindowState: AttributionWindowState;
      readonly value: number;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "AttributionSettings_company";
};
export type AttributionSettings_company$key = {
  readonly " $data"?: AttributionSettings_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttributionSettings_company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attributionWindowState",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttributionSettings_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "AttributionSettings",
        "kind": "LinkedField",
        "name": "attributionSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AttributionWindow",
            "kind": "LinkedField",
            "name": "emailClickWindow",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AttributionWindow",
            "kind": "LinkedField",
            "name": "emailViewWindow",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filterMachineOpened",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AttributionWindow",
            "kind": "LinkedField",
            "name": "textClickWindow",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AttributionWindow",
            "kind": "LinkedField",
            "name": "textViewWindow",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "attributionSettings"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "8bfbd9b9c13fdfae9859d6c07aeb8774";

export default node;
