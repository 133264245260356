import React from 'react';
import { Route } from 'react-router-dom';

import { CompanyFeatureFlagNames } from '@attentive/acore-utils';
import { RoutesFn } from '@attentive/data-router';

import { AIJourneysPerformancePageEntryPoint } from './aiJourneys/pages/AIJourneysPerformancePage.entrypoint';
import { AIProTrialSignUpPageEntryPoint } from '@attentive/audiences-ai/entrypoints';

const routes: RoutesFn<CompanyFeatureFlagNames> = () => (
  <Route Component={React.lazy(() => import('./MlMfe'))}>
    <Route index {...AIJourneysPerformancePageEntryPoint} />
  </Route>
);

const AIProTrialRoutes: RoutesFn<CompanyFeatureFlagNames> = () => (
  <Route Component={React.lazy(() => import('./MlMfe'))}>
    <Route index {...AIProTrialSignUpPageEntryPoint} />
  </Route>
);

export { routes, AIProTrialRoutes };
