import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/ProductCatalog_ProductsLib_Query.graphql';

export const ProductCatalogEntryPoint = createEntryPoint({
  component: () => import('./ProductCatalog'),
  getQueries: ({ getCurrentCompanyId }) => {
    return {
      query: {
        parameters: Query,
        variables: { companyId: getCurrentCompanyId(), first: 20 },
      },
    };
  },
});
