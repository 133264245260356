import { createEntryPoint } from '@attentive/data-router';
import Query from './__generated__/BulkOptOutPhone_SettingsUI_Query.graphql';

export const BulkOptOutPhoneEntryPoint = createEntryPoint({
  component: () => import('./BulkOptOutPhone'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: {
        companyId: getCurrentCompanyId(),
        jobTypeFilter: ['JOB_TYPE_OPT_OUT_PHONES'] as const,
      },
    },
  }),
});
