import React, { useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import produce from 'immer';
import * as queryString from 'query-string';

import {
  attentiveAuthClient,
  checkForUnauthenticatedError,
  useLoginStrategyClient,
  googleSignInClient,
  parseGraphCompanyId,
  parseRedirectPath,
  NeedsPasswordResetError,
  StrategyType,
  Strategy,
  useLoginWithPassword,
} from '@attentive/auth-core';

import {
  Banner,
  Button,
  TextInput,
  InputGroup,
  Heading,
  Text,
  Icon,
  Box,
  PicnicCss,
  FormField,
  IconButton,
  keyframes,
  Link,
  Wordmark,
  Separator,
} from '@attentive/picnic';
import {
  inSneakPreview,
  sneakPreviewCommitSha,
} from '@attentive/sneak-preview-utils/sneak-preview';

import { setDocumentTitle, validateEmail } from '../../utils';
import { AUTH_PAGE_WIDTH } from '../../constants';
import { SignInLayout } from '@attentive/auth-ui';

const PU_FAKE_EMAIL_HEIGHT = 46;
const PU_SSO_HEIGHT = 80;
const PU_MAX_HEIGHT = 112;

// eslint-disable-next-line @typescript-eslint/naming-convention
const PU_spin = keyframes({
  '0%': {
    transform: 'rotate(360deg)',
  },
  '100%': {
    transform: 'rotate(0deg)',
  },
});

const emailBox: PicnicCss = {
  color: '$textDisabled',
  cursor: 'pointer',
  borderRadius: '$radius1',
  border: `solid 1px $borderInput`,
  backgroundColor: '$bgDefault',
  marginTop: '$size4',
  padding: `0 $space4`,
  height: PU_FAKE_EMAIL_HEIGHT,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const loginBoxStyles: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: `$space4 $space0`,
  height: PU_SSO_HEIGHT,
};

// TODO: Figure out apostrophe match issue and extract these constants to the API module
const LOGIN_ERROR_MISSING_CREDENTIAL = 'Invalid authorization header';
const LOGIN_ERROR_INVALID_CREDENTIAL = "Can't login with those credentials";
const LOGIN_ERROR_UNKNOWN = 'Unknown login error. Please try again later.';

function mapLoginErrorMessage(message: string) {
  switch (message) {
    case LOGIN_ERROR_MISSING_CREDENTIAL:
    case LOGIN_ERROR_INVALID_CREDENTIAL:
      return 'Sorry, that password isn’t right.';
    default:
      return LOGIN_ERROR_UNKNOWN;
  }
}

enum LoginFlowActions {
  EnterEmail = 'EnterEmail',
  EmailVerify = 'EmailVerify',
  EnterPassword = 'EnterPassword',
  PasswordVerify = 'PasswordVerify',
  SSORedirect = 'SSORedirect',
}

enum LoginFlowSteps {
  Email = 'Email',
  Password = 'Password',
}

const initialFlowState = {
  currentStep: LoginFlowSteps.Email,
  isLoading: false,
  emailIsReadOnly: false,
  showPasswordField: false,
  showOpeningSSOText: false,
};

const loginFlowReducer = produce((draft: typeof initialFlowState, action: LoginFlowActions) => {
  switch (action) {
    case LoginFlowActions.EnterEmail:
      draft.currentStep = LoginFlowSteps.Email;
      draft.emailIsReadOnly = false;
      draft.isLoading = false;
      draft.showPasswordField = false;
      draft.showOpeningSSOText = false;
      break;
    case LoginFlowActions.EmailVerify:
      draft.isLoading = true;
      break;
    case LoginFlowActions.EnterPassword:
      draft.currentStep = LoginFlowSteps.Password;
      draft.emailIsReadOnly = true;
      draft.showPasswordField = true;
      draft.isLoading = false;
      break;
    case LoginFlowActions.PasswordVerify:
      draft.isLoading = true;
      break;
    case LoginFlowActions.SSORedirect:
      draft.emailIsReadOnly = true;
      draft.showOpeningSSOText = true;
      break;
    default:
      throw new Error(`Unhandled action ${action}`);
  }
});

const currentCommitSha = () => {
  if (inSneakPreview(location.pathname)) {
    return sneakPreviewCommitSha(location.pathname);
  }
};

const SignIn: React.FC = () => {
  const [loginFlow, loginFlowDispatch] = React.useReducer(loginFlowReducer, initialFlowState);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPasswordAsText, setShowPasswordAsText] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [passwordResetReason, setPasswordResetReason] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [loginStrategy, setLoginStrategy] = React.useState<Strategy | null>(null);
  const loginWithPassword = useLoginWithPassword('/2fa');

  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const loginStrategyClient = useLoginStrategyClient();

  React.useEffect(() => {
    const {
      error,
      email: emailAddress = localStorage.getItem('last-email'),
      success,
    } = queryString.parse(location.search);
    if (emailAddress) {
      setEmail(`${emailAddress}`);
    }
    if (error) {
      setErrorMessage(`${error}`);
    }
    if (success) {
      setSuccessMessage(
        'You are now able to sign in to your account with your new password. If you use a password manager, please update your password there.'
      );
    }

    setDocumentTitle('Sign in');
  }, []);

  useEffect(() => {
    if (loginFlow.currentStep === LoginFlowSteps.Email) {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
    }
    if (loginFlow.currentStep === LoginFlowSteps.Password) {
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  }, [loginFlow.currentStep]);

  const verifyLoginStrategy = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      localStorage.setItem('last-email', email);
      loginFlowDispatch(LoginFlowActions.EmailVerify);

      try {
        const strategy = await loginStrategyClient.getStrategy(email);
        setLoginStrategy(strategy);

        if (strategy.type === StrategyType.GOOGLE) {
          loginFlowDispatch(LoginFlowActions.SSORedirect);
          const redirectPath = parseRedirectPath(location);
          const commitSha = currentCommitSha();
          googleSignInClient.signIn({
            clientId: strategy.connectionId,
            redirectPath,
            loginHint: email,
            sneakPreviewCommitSha: commitSha,
          });
        } else if (strategy.type === StrategyType.SAML) {
          loginFlowDispatch(LoginFlowActions.SSORedirect);
          const connectionName = strategy.connectionName;
          const redirectPath = parseRedirectPath(location);
          const commitSha = currentCommitSha();
          const companyId = parseGraphCompanyId(location);
          attentiveAuthClient.loginWithSso(connectionName, redirectPath, commitSha, companyId);
        } else {
          loginFlowDispatch(LoginFlowActions.EnterPassword);
        }
      } catch (err) {
        setErrorMessage(mapLoginErrorMessage(err.message));
        loginFlowDispatch(LoginFlowActions.EnterEmail);
      }
    },
    [email, loginStrategyClient]
  );

  const editEmailField = React.useCallback(() => {
    if (loginFlow.showOpeningSSOText) return;
    loginFlowDispatch(LoginFlowActions.EnterEmail);
    setErrorMessage(null);
    setPasswordResetReason(null);
  }, [loginFlow.showOpeningSSOText]);

  const loginWithCredentials = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const redirectPath = parseRedirectPath(location);
      loginFlowDispatch(LoginFlowActions.PasswordVerify);
      try {
        if (loginStrategy?.type === StrategyType.CREDENTIALS) {
          await loginWithPassword(email, password, redirectPath);
        } else {
          throw new Error(`Invalid strategy type for email login: ${loginStrategy}`);
        }
        return;
      } catch (err) {
        if (err instanceof NeedsPasswordResetError) {
          setPasswordResetReason(err.reason);
        } else if (checkForUnauthenticatedError(err)) {
          setErrorMessage(err.reason);
        } else {
          setErrorMessage(mapLoginErrorMessage(err.message));
        }
        loginFlowDispatch(LoginFlowActions.EnterPassword);
        setPassword('');
      }
    },
    [email, password, loginStrategy, loginWithPassword]
  );

  const formSubmitCallback =
    loginFlow.currentStep === LoginFlowSteps.Email ? verifyLoginStrategy : loginWithCredentials;

  const showBanner = Boolean(errorMessage || successMessage);
  const bannerType = errorMessage ? 'error' : 'info';
  const bannerMessage = errorMessage ? errorMessage : successMessage;

  const isValidEmail = React.useMemo(() => validateEmail(email), [email]);
  const submitButtonDisabled =
    (loginFlow.currentStep === LoginFlowSteps.Email && !isValidEmail) ||
    (loginFlow.currentStep === LoginFlowSteps.Password && !password.length);
  const submitButtonText = loginFlow.currentStep === LoginFlowSteps.Email ? 'Continue' : 'Sign in';
  const showSubmitButton = [LoginFlowSteps.Email, LoginFlowSteps.Password].includes(
    loginFlow.currentStep
  );
  const passwordInputVisible = loginFlow.currentStep === LoginFlowSteps.Password;

  return (
    <SignInLayout>
      <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
        <form onSubmit={formSubmitCallback}>
          <Wordmark
            title="Attentive signin page"
            width="140px"
            css={{ mb: '$space12', display: 'block' }}
          />
          <Heading css={{ marginBottom: '$space6' }} variant="page">
            Sign in
          </Heading>
          {showBanner && (
            <Banner variant={bannerType} css={{ mb: '$space4' }}>
              <Banner.Text>{bannerMessage}</Banner.Text>
            </Banner>
          )}
          {passwordResetReason && (
            <Banner variant="error" css={{ mb: '$space4' }}>
              <Banner.Text>
                {passwordResetReason} Please{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/forgot-password${email ? `?email=${encodeURIComponent(email)}` : ''}`}
                >
                  create a new password.
                </Link>
              </Banner.Text>
            </Banner>
          )}
          {loginFlow.emailIsReadOnly ? (
            <>
              <Box as="label" css={{ mb: '$space2', display: 'block' }}>
                Email address
              </Box>
              <Box data-heap-redact-text css={emailBox} tabIndex={0} onClick={editEmailField}>
                {email}
                <IconButton
                  size="small"
                  iconName="Pencil"
                  description="Edit Email"
                  onClick={editEmailField}
                />
              </Box>
            </>
          ) : (
            <FormField>
              <FormField.Label htmlFor="email">Email address</FormField.Label>
              <TextInput
                id="email"
                ref={emailInputRef}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
              />
            </FormField>
          )}
          <Box
            css={{
              marginTop: '$space4',
              transition: 'max-height 250ms ease, opacity 250ms ease',
              maxHeight: passwordInputVisible ? PU_MAX_HEIGHT : 0,
              opacity: passwordInputVisible ? 1 : 0,
              visibility: !passwordInputVisible ? 'hidden' : 'visible',
            }}
            aria-hidden={!passwordInputVisible}
          >
            <FormField>
              <FormField.Label htmlFor="password">Password</FormField.Label>
              <InputGroup>
                <TextInput
                  aria-hidden={!passwordInputVisible}
                  ref={passwordInputRef}
                  id="password"
                  type={showPasswordAsText ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setErrorMessage(null);
                    setPasswordResetReason(null);
                  }}
                />
                <InputGroup.RightElement>
                  <InputGroup.InlineButton
                    type="button"
                    onClick={() => setShowPasswordAsText(!showPasswordAsText)}
                  >
                    {showPasswordAsText ? 'Hide' : 'Show'}
                  </InputGroup.InlineButton>
                </InputGroup.RightElement>
              </InputGroup>
            </FormField>
          </Box>

          {showSubmitButton && (
            <Button
              type="submit"
              disabled={submitButtonDisabled}
              css={{
                marginTop: passwordInputVisible ? '$space4' : '$space0',
                width: '100%',
              }}
              loading={loginFlow.isLoading}
              data-client-ui-id="login-button"
            >
              {submitButtonText}
            </Button>
          )}
          {loginFlow.showOpeningSSOText && (
            <Box css={loginBoxStyles}>
              <Text>
                Opening your single sign-on provider{' '}
                <Icon
                  name="Sync"
                  size="small"
                  css={{
                    verticalAlign: 'bottom',
                    animation: `${PU_spin} 2s linear infinite`,
                  }}
                />
              </Text>
            </Box>
          )}
          <Link
            as={RouterLink}
            to={`/forgot-password${email ? `?email=${encodeURIComponent(email)}` : ''}`}
            css={{ marginTop: '$space8', display: 'block', textAlign: 'center' }}
          >
            Reset password
          </Link>
          <Separator
            css={{ marginTop: '$space12', marginBottom: '$space6', textAlign: 'center' }}
          />
          <Text css={{ textAlign: 'center' }}>
            Don't have an Attentive account?{' '}
            <Link href="https://www.attentivemobile.com/demo">Request a demo</Link>
          </Text>
        </form>
      </Box>
    </SignInLayout>
  );
};

export { SignIn };
