import _SegmentDetailsPageEntrypoint_SegmentationUI_Query from "./__generated__/SegmentDetailsPageEntrypoint_SegmentationUI_Query.graphql";
import { graphql } from '@attentive/data';
import { createEntryPoint } from '@attentive/data-router';
import { convertInternalSegmentIdToNodeId } from '@attentive/targeting-common';
import Query from './__generated__/SegmentDetailsPageEntrypoint_SegmentationUI_Query.graphql';
_SegmentDetailsPageEntrypoint_SegmentationUI_Query;
export const SegmentDetailsPageEntrypoint = createEntryPoint({
  component: () => import('./SegmentDetails'),
  getQueries: _ref => {
    let {
      getCurrentCompanyId,
      params
    } = _ref;
    return {
      query: {
        parameters: Query,
        variables: {
          companyId: getCurrentCompanyId(),
          segmentId: params.id ? convertInternalSegmentIdToNodeId(params.id) : ''
        }
      }
    };
  }
});