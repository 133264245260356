import './polyfill';
import * as React from 'react';
import * as ReactDom from 'react-dom';

import {
  initDatadog,
  addDatadogContext,
  performanceMark,
  MARK_CLIENT_UI_STARTED,
  MARK_CLIENT_UI_RENDERING,
  initRestApi,
  AuthSession,
  Tracker,
  AppStore,
  OperatingModes,
  getCompanyFeatureFlag,
  getCurrentCompanyId,
  getPermission,
  getRoles,
  enableSessionReplay,
  fetchAccountPermissions,
  hydrateAccountPermissionAtoms,
} from '@attentive/acore-utils';
import {
  AuthRedirecting,
  initAuthLoginCallback,
  initializeAuthCoreLogger,
} from '@attentive/auth-core';
import { createRelayClientExtensions } from '@attentive/data';
import { Text } from '@attentive/picnic';

import { AppProviders } from './AppProviders';
import { routes } from './routes';
import {
  checkPlatformAvailability,
  parseAppDocumentEnvVars,
  checkUnsupportedBrowser,
  initPicnic,
  initRelay,
  initSneakPreview,
  validateAuthStatus,
  establishSessionEnvVars,
  establishEnvironmentVariables,
  logEnvVarOverrides,
  establishPlatformEnvVars,
  checkValidSneakPreview,
  establishApiDataSource,
} from './utils/app-init-steps';
import { historyBaseName, renderError } from './utils/app';
import { AbortInit } from './utils/abort-init-error';
import { logError } from './utils/loggerInstance';
import { AppData, hydrateAppDataAtoms } from './store/app-data';
import { resetEnvVarsInSessionStorage } from './utils/app-persistence';
// eslint-disable-next-line import/no-extraneous-dependencies
import { REACT_18_ERROR_INFO } from '@attentive/test-utils/react-18-warning';
import { fetchClientUIAppBodyQuery } from './components/authenticated-app/ClientUIAppBodyQuery';

import { hydrateEnvVarAtoms } from './store/env-vars';
import { fetchSiteData, hydrateSiteData } from './store/site-data';
import { hydrateAppFeatureFlags } from './components/authenticated-app/AppFeatureFlags';
import { hydrateOnboarding } from './components/onboarding/useOnboarding';
import {
  fetchAuthAtoms,
  hydrateAuthAtoms,
} from './components/authenticated-app/AuthorizationDataProvider';
import { createDataRouter } from '@attentive/data-router';

const appName = 'client-ui';
const appDocumentEnvVarSelector = 'meta[name="attentive-env-vars"]';
const datadogApplicationId = '19de6329-10c7-4daf-b540-7fe0cb87e5c3';
const datadogClientToken = 'pub7e9833119e229e59c8711711ab130575';

export async function initialize() {
  performanceMark(MARK_CLIENT_UI_STARTED);

  checkUnsupportedBrowser();

  // We set the userId in userLeap as soon as possible, it's too late to wait for the main
  // user data to come through a graph query, so we pull it out of the JWT.
  const decodedToken = AuthSession.retrieveTokenDecoded();
  if (decodedToken) {
    Tracker.initializeUserLeap(decodedToken.userId);
  }

  await initPicnic();

  const appDocumentMetaTag = document.querySelector(appDocumentEnvVarSelector);
  const appDocumentEnvVars = parseAppDocumentEnvVars(appDocumentMetaTag?.getAttribute('value'));

  const sneakPreviewSha = await initSneakPreview(window.location);
  checkValidSneakPreview(sneakPreviewSha, appDocumentEnvVars.commitSha);

  const platformEnvVars = await establishPlatformEnvVars(window.location);
  const sessionEnvVars = establishSessionEnvVars(
    window.location,
    appDocumentEnvVars.allowDevApiOverride
  );

  const appInitEnvVars = {
    inSneakPreview: Boolean(sneakPreviewSha),
    apiHostOverridden:
      !!sessionEnvVars.graphqlApiOrigin &&
      sessionEnvVars.graphqlApiOrigin !== appDocumentEnvVars.graphqlApiOrigin,
  };

  const envVars = establishEnvironmentVariables(
    appDocumentEnvVars,
    appInitEnvVars,
    platformEnvVars,
    sessionEnvVars
  );

  const graphqlApiUrl = `${envVars.graphqlApiOrigin}${envVars.graphqlApiPathPrefix}`;

  logEnvVarOverrides(appDocumentEnvVars, appInitEnvVars, platformEnvVars, sessionEnvVars, envVars);

  await establishApiDataSource(envVars.apiDataSource);

  initRestApi({ baseUrl: envVars.apiUrl, apiDataSource: envVars.apiDataSource });

  const relayEnvironment = initRelay(graphqlApiUrl, envVars.apiDataSource);

  initDatadog({
    env: envVars.envName,
    commitSha: envVars.commitSha,
    restApiUrl: envVars.apiUrl,
    graphqlApiOrigin: envVars.graphqlApiOrigin,
    appName,
    datadogApplicationId,
    datadogClientToken,
    appDocumentBuildTimestamp: envVars.commitDetails.timestamp,
    loggingEnabled: envVars.datadogLoggingEnabled,
    sessionSampleRate: envVars.datadogSampleRate,
  });
  initializeAuthCoreLogger(appName);

  await checkPlatformAvailability();

  try {
    await initAuthLoginCallback(window.location);
  } catch (err) {
    if (err instanceof AuthRedirecting) {
      throw new AbortInit();
    }
    throw err;
  }

  const { status, companyId, companyRestId } = await validateAuthStatus(relayEnvironment);

  const appData: AppData = {
    currentCompanyId: companyId || '',
  };

  createRelayClientExtensions(relayEnvironment, {
    transitionCompanyId: null,
    appTeardownReasonMessage: null,
    userAuthState: status,
  });

  addDatadogContext('companyId', companyRestId);

  hydrateAppDataAtoms(appData);
  hydrateEnvVarAtoms(envVars);

  let fetchQueries:
    | [ReturnType<typeof fetchSiteData>]
    | [
        ReturnType<typeof fetchSiteData>,
        ReturnType<typeof fetchClientUIAppBodyQuery>,
        ReturnType<typeof fetchAuthAtoms>,
        ReturnType<typeof fetchAccountPermissions>
      ] = [fetchSiteData(relayEnvironment)];

  if (companyId) {
    fetchQueries = [
      ...fetchQueries,
      fetchClientUIAppBodyQuery({
        relayEnvironment,
        isEmergencyMode: envVars.operatingMode === OperatingModes.Emergency,
        companyId,
      }),
      fetchAuthAtoms({ relayEnvironment, companyId }),
      fetchAccountPermissions({ relayEnvironment, companyId }),
    ];
  }

  const [siteData, initialAppQueryRef, authData, accountPermissionData] = await Promise.all(
    fetchQueries
  );

  if (authData && initialAppQueryRef && accountPermissionData) {
    hydrateAuthAtoms(authData);
    hydrateAccountPermissionAtoms(accountPermissionData);
    hydrateAppFeatureFlags(initialAppQueryRef);
    hydrateOnboarding(initialAppQueryRef);
  }

  if (getCompanyFeatureFlag('ENABLE_DATADOG_SESSION_REPLAY')) {
    enableSessionReplay();
  }

  hydrateSiteData(siteData);

  const router = createDataRouter(
    routes(),
    {
      relayEnvironment,
      getCompanyFeatureFlag,
      getCurrentCompanyId,
      getPermission,
      getRoles,
    },
    {
      basename: historyBaseName(window.location),
    }
  );

  performanceMark(MARK_CLIENT_UI_RENDERING);
  ReactDom.render(
    <AppProviders relayEnvironment={relayEnvironment} router={router} store={AppStore.store} />,
    document.getElementById('app')
  );
  REACT_18_ERROR_INFO(!envVars.bundledWithEsbuild);
}

initialize().catch((error: Error) => {
  if (error instanceof AbortInit) {
    return null;
  }
  console.error(error);

  resetEnvVarsInSessionStorage();
  console.warn('We have cleared the environment variable storage.');

  logError(error);

  renderError(
    <Text>
      Our app can’t seem to start up. We’re working on a solution &mdash; please check back soon!
    </Text>
  );
});
