import _SegmentSubscribersPageEntrypoint_SegmentationUI_Query from "./__generated__/SegmentSubscribersPageEntrypoint_SegmentationUI_Query.graphql";
import { graphql } from '@attentive/data';
import { createEntryPoint } from '@attentive/data-router';
import { convertInternalSegmentIdToNodeId } from '@attentive/targeting-common';
import Query from './__generated__/SegmentSubscribersPageEntrypoint_SegmentationUI_Query.graphql';
_SegmentSubscribersPageEntrypoint_SegmentationUI_Query;
export const SegmentSubscribersPageEntrypoint = createEntryPoint({
  component: () => import('./SegmentSubscribers'),
  getQueries: _ref => {
    let {
      getCurrentCompanyId,
      params
    } = _ref;
    return {
      query: {
        parameters: Query,
        variables: {
          companyId: getCurrentCompanyId(),
          segmentId: params.id ? convertInternalSegmentIdToNodeId(params.id) : ''
        }
      }
    };
  }
});