import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/ProductDetail_ProductLib_Query.graphql';

export const ProductDetailEntryPoint = createEntryPoint({
  component: () => import('./ProductDetail'),
  getQueries: ({ getCurrentCompanyId, params }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId(), productId: params.id || '' },
    },
  }),
});
