/**
 * @generated SignedSource<<ec28239f1a32cc9f78c75c9fbdf0eec9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HelpCenterMenuContent_Legacy_ClientUIFragment_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ZendeskLiveChatAcoreUtilsFragment_query" | "useZendeskLoaderAcoreUtilsFragment_query">;
  readonly " $fragmentType": "HelpCenterMenuContent_Legacy_ClientUIFragment_query";
};
export type HelpCenterMenuContent_Legacy_ClientUIFragment_query$key = {
  readonly " $data"?: HelpCenterMenuContent_Legacy_ClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"HelpCenterMenuContent_Legacy_ClientUIFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HelpCenterMenuContent_Legacy_ClientUIFragment_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZendeskLiveChatAcoreUtilsFragment_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useZendeskLoaderAcoreUtilsFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b63de63e8b73262623e49d75fcec9a2e";

export default node;
