/**
 * @generated SignedSource<<203ebe69f6493d6f017edaffa05a3b98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttributionWindowState = "ATTRIBUTION_WINDOW_STATE_NULL" | "ATTRIBUTION_WINDOW_STATE_SET" | "ATTRIBUTION_WINDOW_STATE_UNKNOWN" | "%future added value";
export type AttributionSettingsQuery$variables = {
  companyId: string;
};
export type AttributionSettingsQuery$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"AttributionSettings_company">;
  };
};
export type AttributionSettingsQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly attributionSettings: {
      readonly emailClickWindow: {
        readonly value: number;
      };
      readonly emailViewWindow: {
        readonly attributionWindowState: AttributionWindowState;
        readonly value: number;
      };
      readonly filterMachineOpened: boolean;
      readonly textClickWindow: {
        readonly value: number;
      };
      readonly textViewWindow: {
        readonly attributionWindowState: AttributionWindowState;
        readonly value: number;
      };
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type AttributionSettingsQuery = {
  rawResponse: AttributionSettingsQuery$rawResponse;
  response: AttributionSettingsQuery$data;
  variables: AttributionSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attributionWindowState",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttributionSettingsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AttributionSettings_company"
                }
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttributionSettingsQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AttributionSettings",
                "kind": "LinkedField",
                "name": "attributionSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "emailClickWindow",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "emailViewWindow",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterMachineOpened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "textClickWindow",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "textViewWindow",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1aca2bd55a8dfce1d60e7aeb8d1bca95",
    "id": null,
    "metadata": {},
    "name": "AttributionSettingsQuery",
    "operationKind": "query",
    "text": "query AttributionSettingsQuery(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...AttributionSettings_company\n    }\n    id\n  }\n}\n\nfragment AttributionSettings_company on Company {\n  id\n  attributionSettings {\n    emailClickWindow {\n      value\n    }\n    emailViewWindow {\n      value\n      attributionWindowState\n    }\n    filterMachineOpened\n    textClickWindow {\n      value\n    }\n    textViewWindow {\n      value\n      attributionWindowState\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e43f04ae744c151300e51314c618b1e3";

export default node;
