import {
  fetchAccountPermissions,
  hydrateAccountPermissionAtoms,
  useCurrentCompanyId,
} from '@attentive/acore-utils';
import React, { ReactElement } from 'react';

import { useRefreshTimer } from '../clocks/useRefreshTimer';

import { useRelayEnvironment } from 'react-relay';

const REFRESH_INTERVAL_MS = 60 * 1000;

export const AccountPermissionDataProvider: React.FC = ({ children }) => {
  const relayEnvironment = useRelayEnvironment();
  const companyId = useCurrentCompanyId();
  useRefreshTimer(async () => {
    hydrateAccountPermissionAtoms(await fetchAccountPermissions({ relayEnvironment, companyId }));
  }, REFRESH_INTERVAL_MS);

  return children as ReactElement;
};
