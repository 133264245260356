import { Box, IconButton, styled, Text, Link } from '@attentive/picnic';
import {
  ShopifyAttributionOptInStatesResult,
  ShopifyAttributionOptInStatus,
} from '@attentive/reporting-platform';

import React from 'react';

const UnreadIcon = styled('div', {
  width: '$size3',
  height: '$size3',
  borderRadius: '$radiusMax',
  backgroundColor: '$hyperlinkBlue700',
});

const AttributionStateMessages = {
  [ShopifyAttributionOptInStatus.ELIGIBLE]: {
    title: 'Upcoming attribution changes with direct Shopify order data',
    description:
      'On May 1, 2025, your conversion and revenue data will be attributed to Shopify order data. You can opt-in early on the Home page.',
    callToAction: 'Go to Home page',
    url: '/home',
    dismissable: false,
  },
  [ShopifyAttributionOptInStatus.REFRESHING]: {
    title: 'Updating attribution changes with direct Shopify order data',
    description:
      "We're retroactively updating all historical reporting data from 2023 onward to align with the new attribution settings. Please wait while we process these updates.",
    callToAction: undefined,
    url: undefined,
    dismissable: false,
  },
  [ShopifyAttributionOptInStatus.COMPLETED]: {
    title: 'Attribution changes with direct Shopify order data complete!',
    description:
      'Your attribution changes are live! You can now view the updated reporting data reflecting the new settings.',
    callToAction: 'Go to Analytics dashboard',
    url: '/analytics/dashboard',
    dismissable: true,
  },
};

export const ShopifyAttributionRow = ({
  shopifyAttributionStates,
}: {
  shopifyAttributionStates?: ShopifyAttributionOptInStatesResult;
}) => {
  if (!shopifyAttributionStates) {
    return null;
  }

  const { status, isSuccessDismissed, setSuccessDismissed } = shopifyAttributionStates;

  if (!status) {
    return null;
  }

  const notEligible = status === ShopifyAttributionOptInStatus.INELIGIBLE;
  const isCompleted = status === ShopifyAttributionOptInStatus.COMPLETED;

  if (notEligible || (isCompleted && isSuccessDismissed)) {
    return null;
  }

  const { title, description, callToAction, url, dismissable } =
    AttributionStateMessages[status] || {};

  return (
    <Box css={{ py: '$space3', px: '$space4', display: 'flex', position: 'relative' }}>
      <Box css={{ flex: 1, pr: '$space1' }}>
        <Text
          variant="caption"
          css={{
            fontWeight: '$bold',
          }}
        >
          {title}
        </Text>
        <Text variant="caption" css={{ pt: '$space1' }}>
          {description}
        </Text>
        {callToAction && (
          <Text
            as={Link}
            variant="caption"
            href={url}
            css={{ pt: '$space1' }}
            data-nav-id="notifications-menu-cta"
          >
            {callToAction}
          </Text>
        )}
      </Box>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UnreadIcon aria-label="Unread notification" />
      </Box>
      {dismissable && (
        <IconButton
          iconName="X"
          description="Dismiss notification"
          size="extraSmall"
          css={{ position: 'absolute', top: '$space3', right: '$space3' }}
          onClick={() => setSuccessDismissed(true)}
          data-nav-id="notifications-menu-dismiss"
        />
      )}
    </Box>
  );
};
