/**
 * @generated SignedSource<<a5f0d0ed5c763e450d4b2759a5913132>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBarClientUIFragment_query$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuClientUIFragment_company">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AccountMenuClientUIFragment_query" | "HelpCenterMenu_Legacy_ClientUIFragment_query" | "ZendeskLiveChatAcoreUtilsFragment_query" | "useZendeskLoaderAcoreUtilsFragment_query">;
  readonly " $fragmentType": "TopBarClientUIFragment_query";
};
export type TopBarClientUIFragment_query$key = {
  readonly " $data"?: TopBarClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopBarClientUIFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopBarClientUIFragment_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountMenuClientUIFragment_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HelpCenterMenu_Legacy_ClientUIFragment_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZendeskLiveChatAcoreUtilsFragment_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useZendeskLoaderAcoreUtilsFragment_query"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationsMenuClientUIFragment_company"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "company"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a0ecf6cf5c2addbcc9cd8c13aa5ffcb8";

export default node;
