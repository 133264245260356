import React from 'react';

import { createPresenter } from '@attentive/data';
import { useCurrentCompanyId } from '@attentive/acore-utils';

import AppLoading from '../../pages/AppLoading';
import { AppCurrentUser } from './AppCurrentUser';
import { AppInitDatadogLogger } from './AppInitDatadogLogger';
import { AuthorizationDataProvider } from './AuthorizationDataProvider';
import { AccountPermissionDataProvider } from './AccountPermissionDataProvider';

import { useEmergencyMode } from '../../store/env-vars';

import ClientUIAppBodyQuery, {
  ClientUIAppBodyQuery as ClientUIAppBodyQueryType,
} from './__generated__/ClientUIAppBodyQuery.graphql';
import { AppFeatureFlags } from './AppFeatureFlags';

const [useAppBodyQueryData, AppBodyPresenter] =
  createPresenter<ClientUIAppBodyQueryType>(ClientUIAppBodyQuery);

export { useAppBodyQueryData, AppBodyPresenter };

export const AppBody: React.FC = ({ children }) => {
  const currentCompanyId = useCurrentCompanyId();
  const isEmergencyMode = useEmergencyMode();

  return (
    <AppBodyPresenter
      fallback={<AppLoading />}
      vars={{
        companyId: currentCompanyId,
        isEmergencyMode,
      }}
    >
      <AppCurrentUser>
        <AuthorizationDataProvider>
          <AccountPermissionDataProvider>
            <AppFeatureFlags>
              <AppInitDatadogLogger />
              {children}
            </AppFeatureFlags>
          </AccountPermissionDataProvider>
        </AuthorizationDataProvider>
      </AppCurrentUser>
    </AppBodyPresenter>
  );
};
